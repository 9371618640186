import appStrings from '@/app/utility/string.utility';
import commonHelper from '../../../../../../utility/common.helper.utility';
import URL_UTILITY from '../../../../../../utility/url.utility';
export default {
  name: 'updateIndirectTax',
  components: {},
  props: ['indirectTaxRowDetail'],
  data() {
    return {
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      defaultValue: 'No Record Available',
      existingIndirectTaxRecord: {
        tax_group: null,
        issuing_authority: null,
        tax_num: null,
        active: false
      },
      updatedIndirectTaxRecord: {
        tax_group: null,
        issuing_authority: null,
        tax_num: null,
        state: null,
        posting_status_meaning: null,
        approval_date: null,
        approval_status: null,
        approved_by: null,
        rejected_reason: null,
        active: false
      },
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        }
      ],
      showRejectIndirectTaxModal: false,
      reject: null,
      showDownloadModal: false,
      showOpenKmModal: false,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      viewDocumentFlag: false,
      indirectTaxSubMenuId: null
    };
  },
  mounted() {
    this.getPartySubMenuId();
    this.fillRecordsUpdatedRecord();
    if (this.indirectTaxRowDetail.party_indirect_tax_id) {
      this.getPartyPortalExistingIndirectTaxRecord();
    } else {
      this.existingIndirectTaxRecord.tax_group = this.defaultValue;
      this.existingIndirectTaxRecord.issuing_authority = this.defaultValue;
      this.existingIndirectTaxRecord.tax_num = this.defaultValue;
      this.existingIndirectTaxRecord.state = this.defaultValue;
    }
  },
  methods: {
    fillRecordsUpdatedRecord() {
      this.updatedIndirectTaxRecord = this.indirectTaxRowDetail;
    },
    getPartyPortalExistingIndirectTaxRecord() {
      const payload = {
        party_indirect_tax_id:
          this.indirectTaxRowDetail.party_indirect_tax_id ||
          this.updatedIndirectTaxRecord.party_indirect_tax_id
      };
      this.loader = true;
      this.$store
        .dispatch(
          'portalRealApp/getPartyPortalExistingIndirectTaxRecord',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.existingIndirectTaxRecord = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showApproveIndirectTax() {
      const payload = {
        pp_party_indirect_tax_id: this.indirectTaxRowDetail
          .pp_party_indirect_tax_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/approvePartyPortalIndirectTax', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.updatedIndirectTaxRecord = response.data.data;
            this.getPartyPortalExistingIndirectTaxRecord();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHideRejectIndirectTaxModal(flag) {
      this.showRejectIndirectTaxModal = flag;
    },
    rejectIndirectTax() {
      if (this.reject) {
        const payload = {
          pp_party_indirect_tax_id: this.indirectTaxRowDetail
            .pp_party_indirect_tax_id,
          rejected_reason: this.reject
        };
        this.loader = true;
        this.$store
          .dispatch('portalRealApp/rejectPartyPortalIndirectTax', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.updatedIndirectTaxRecord = response.data.data;
              this.showRejectIndirectTaxModal = false;
              this.reject = null;
              this.getPartyPortalExistingIndirectTaxRecord();
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
              this.reject = null;
              this.showRejectIndirectTaxModal = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Enter Reject Reason');
      }
    },
    getPartySubMenuId() {
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartySubMenuId')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.indirectTaxSubMenuId =
              response.data.data.pp_party_indirect_tax_sub_menu;
            this.getUploadedDocDtl();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    getOpenKmAuthToken() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: 326,
        sub_menu_id: this.indirectTaxSubMenuId,
        record_id: this.indirectTaxRowDetail.pp_party_indirect_tax_id,
        cat: 'DOCUMENT'
      };
      this.downloadItems = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.viewDocumentFlag = true;
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
};
