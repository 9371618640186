import commonHelper from '../../../../../utility/common.helper.utility';
import updateAddressDetails from './updateAddress';
export default {
  name: 'address',
  watch: {
    currentPage: function() {
      this.getPartyPortalAddressById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPartyPortalAddressById();
    }
  },
  components: { commonHelper, updateAddressDetails },
  props: ['partyDetails'],
  data() {
    return {
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      addressData: [],
      addressFields: [
        {
          key: 'address_type_lookup_meaning',
          label: 'Address Type'
        },
        {
          key: 'address',
          label: 'Complete Address'
        },
        {
          key: 'address_name',
          label: 'Address'
        },
        {
          key: 'address_1'
        },
        {
          key: 'address_2'
        },
        {
          key: 'address_3'
        },
        {
          key: 'city_name',
          label: 'City'
        },
        {
          key: 'state_name',
          label: 'State'
        },
        {
          key: 'country_name',
          label: 'Country'
        },
        {
          key: 'pincode_name',
          label: 'Pincode'
        },
        {
          key: 'posting_status_meaning',
          label: 'Posting Status'
        },
        {
          key: 'approval_date'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'approved_by'
        },
        {
          key: 'rejected_reason'
        }
      ],
      showAddAddressUpdateModal: false,
      addressRowDetail: null
    };
  },
  mounted() {
    this.getPartyPortalAddressById();
  },
  methods: {
    getPartyPortalAddressById() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        party_id: this.partyDetails.party_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartyPortalAddressById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.addressData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.addressRowDetail = item;
      this.showHideAddressUpdateModal(true);
    },
    showHideAddressUpdateModal(flag) {
      this.showAddAddressUpdateModal = flag;
      if (!flag) {
        this.getPartyPortalAddressById();
      }
    }
  }
};
