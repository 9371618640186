import commonHelper from '../../../../../utility/common.helper.utility';
import updateContactDetails from './updateContactDetails';
export default {
  name: 'directTax',
  components: { commonHelper, updateContactDetails },
  props: ['partyDetails'],
  data() {
    return {
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      contactDetailsData: [
        {
          communication_details: []
        }
      ],
      contactDetailsFields: [
        {
          key: 'contact_purpose'
        },
        {
          key: 'salutation'
        },
        {
          key: 'first_name'
        },
        {
          key: 'middle_name'
        },
        {
          key: 'last_name'
        },
        {
          key: 'contact_purpose'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'posting_status_meaning',
          label: 'Posting Status'
        },
        {
          key: 'approval_date'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'approved_by'
        },
        {
          key: 'rejected_reason'
        }
      ],
      contact_details_fields: [
        {
          key: 'comm_type',
          label: 'Contact Type'
        },
        {
          key: 'area_code'
        },
        {
          key: 'country_code'
        },
        {
          key: 'contact_num',
          label: 'Contact Number'
        },
        {
          key: 'email'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'posting_status_meaning',
          label: 'Posting Status'
        },
        {
          key: 'approval_date'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'approved_by'
        },
        {
          key: 'rejected_reason'
        }
      ],
      contactDetailIndex: null,
      showAddContactDetailsUpdateModal: false,
      contactDetailsRowDetail: null,
      invoiceDetails: {}
    };
  },
  mounted() {
    this.getPartyPortalContactDetailsById();
  },
  methods: {
    showVendor(index, item) {
      this.contactDetailIndex = index;
      if (item.item.approval_status === 'APPROVED') {
        item.toggleDetails();
      } else {
        alert('Please Approve the Headers First');
      }
    },
    getPartyPortalContactDetailsById() {
      const payload = {
        party_id: this.partyDetails.party_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartyPortalContactDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.contactDetailsData = response.data.data;
            this.contactDetailsData.map(data => {
              data.original_file_name = 'Details';
              return data;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.contactDetailsRowDetail = item;
      this.showHideContactDetailsUpdateModal(true);
    },
    showHideContactDetailsUpdateModal(flag) {
      this.showAddContactDetailsUpdateModal = flag;
      if (!flag) {
        this.getPartyPortalContactDetailsById();
      }
    }
  }
};
