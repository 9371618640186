import commonHelper from '../../../../../utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from '../../../../../utility/url.utility';
export default {
  name: 'Details',
  components: {},
  props: ['partyDetails'],
  data() {
    return {
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      loader: false,
      showAlert: false,
      isSuccess: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      responseMsg: '',
      existingDetails: {
        party_type: null,
        party_cat: null,
        party_name: null,
        pan_no: null,
        salutation: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        date_of_birth: null,
        aadhar_no: null,
        is_foreign: false
      },
      updatedDetails: {
        party_type_vset_meaning: null,
        party_cat_vset_meaning: null,
        party_name: null,
        pan_no: null,
        salutation: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        date_of_birth: null,
        aadhar_no: null,
        posting_status_meaning: null,
        approval_date: null,
        approval_status: null,
        approved_by: null,
        rejected_reason: null,
        is_foreign: false
      },
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        }
      ],
      showRejectDetailModal: false,
      reject: null,
      showDownloadModal: false,
      showOpenKmModal: false,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      viewDocumentFlag: false,
      detailsSubMenuId: null
    };
  },
  mounted() {
    this.getPartyPortalDetailsById();
    this.getPartyPortalExistingDetailsRecord();
    this.getPartySubMenuId();
  },
  methods: {
    getPartyPortalDetailsById() {
      const payload = {
        pp_party_id: this.partyDetails.pp_party_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartyPortalDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.updatedDetails = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPartyPortalExistingDetailsRecord() {
      const payload = {
        party_id: this.partyDetails.party_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartyPortalExistingDetailsRecord', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.existingDetails = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showApproveDetails() {
      const payload = {
        pp_party_id: this.partyDetails.pp_party_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/approvePartyPortalDetails', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.updatedDetails = response.data.data;
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getPartyPortalExistingDetailsRecord();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHideRejectDetailModal(flag) {
      this.showRejectDetailModal = flag;
      if (!flag) {
        this.getPartyPortalDetailsById();
      }
    },
    rejectDetails() {
      if (this.reject) {
        const payload = {
          pp_party_id: this.partyDetails.pp_party_id,
          rejected_reason: this.reject
        };
        this.loader = true;
        this.$store
          .dispatch('portalRealApp/rejectPartyPortalDetails', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 201) {
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.reject = null;
              this.showRejectDetailModal = false;
              this.getPartyPortalExistingDetailsRecord();
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
              this.reject = null;
              this.showRejectDetailModal = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Enter Reject Reason');
      }
    },
    getPartySubMenuId() {
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartySubMenuId')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.detailsSubMenuId =
              response.data.data.pp_party_details_sub_menu;
            this.getUploadedDocDtl();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      // if (flag) {
      //   this.getUploadedDocDtl();
      // }
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    getOpenKmAuthToken() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: 326,
        sub_menu_id: this.detailsSubMenuId,
        record_id: this.partyDetails.pp_party_id
      };
      this.downloadItems = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.viewDocumentFlag = true;
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
};
