import appStrings from '@/app/utility/string.utility';
import commonHelper from '../../../../../../utility/common.helper.utility';
import URL_UTILITY from '../../../../../../utility/url.utility';
export default {
  name: 'updateDirectTax',
  components: {},
  props: ['directTaxRowDetail', 'documentFlag'],
  data() {
    return {
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      defaultValue: 'No Record Available',
      existingDirectTaxRecord: {
        tax_group: null,
        tan_no: null,
        active: false
      },
      updatedDirectTaxRecord: {
        tax_group: null,
        tan_no: null,
        posting_status_meaning: null,
        approval_date: null,
        approval_status: null,
        approved_by: null,
        rejected_reason: null,
        active: false
      },
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        }
      ],
      showRejectDirectTaxModal: false,
      reject: null,
      showDownloadModal: false,
      showOpenKmModal: false,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      viewDocumentFlag: false,
      directTaxSubMenuId: null
    };
  },
  mounted() {
    this.getPartySubMenuId();
    this.fillRecordsUpdatedRecord();
    if (this.directTaxRowDetail.party_direct_tax_id) {
      this.getPartyPortalExistingDirectTaxRecord();
    } else {
      this.existingDirectTaxRecord.tax_group = this.defaultValue;
      this.existingDirectTaxRecord.tan_no = this.defaultValue;
    }
  },
  methods: {
    fillRecordsUpdatedRecord() {
      this.updatedDirectTaxRecord = this.directTaxRowDetail;
    },
    getPartyPortalExistingDirectTaxRecord() {
      const payload = {
        party_direct_tax_id:
          this.directTaxRowDetail.party_direct_tax_id ||
          this.updatedDirectTaxRecord.party_direct_tax_id
      };
      this.loader = true;
      this.$store
        .dispatch(
          'portalRealApp/getPartyPortalExistingDirectTaxRecord',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.existingDirectTaxRecord = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showApproveDirectTax() {
      const payload = {
        pp_party_direct_tax_id: this.directTaxRowDetail.pp_party_direct_tax_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/approvePartyPortalDirectTax', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.updatedDirectTaxRecord = response.data.data;
            this.getPartyPortalExistingDirectTaxRecord();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHideRejectDirectTaxModal(flag) {
      this.showRejectDirectTaxModal = flag;
    },
    rejectDirectTax() {
      if (this.reject) {
        const payload = {
          pp_party_direct_tax_id: this.directTaxRowDetail
            .pp_party_direct_tax_id,
          rejected_reason: this.reject
        };
        this.loader = true;
        this.$store
          .dispatch('portalRealApp/rejectPartyPortalDirectTax', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.updatedDirectTaxRecord = response.data.data;
              this.showRejectDirectTaxModal = false;
              this.reject = null;
              this.getPartyPortalExistingDirectTaxRecord();
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
              this.reject = null;
              this.showRejectDirectTaxModal = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Enter Reject Reason');
      }
    },
    getPartySubMenuId() {
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartySubMenuId')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.directTaxSubMenuId =
              response.data.data.pp_party_direct_tax_sub_menu;
            this.getUploadedDocDtl();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      // if (flag) {
      //   this.getUploadedDocDtl();
      // }
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    getOpenKmAuthToken() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: 326,
        sub_menu_id: this.directTaxSubMenuId,
        record_id: this.directTaxRowDetail.pp_party_direct_tax_id,
        cat: 'DOCUMENT'
      };
      this.downloadItems = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.viewDocumentFlag = true;
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
};
