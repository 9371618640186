import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from '../../../../../../utility/url.utility';
import commonHelper from '../../../../../../utility/common.helper.utility';
export default {
  name: 'udpateAddress',
  components: {},
  props: ['addressRowDetail'],
  data() {
    return {
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      defaultValue: 'No Record Available',
      existingAddressRecord: {
        address_type: null,
        address_name: null,
        address_1: null,
        address_2: null,
        address_3: null,
        country_name: null,
        state_name: null,
        city_name: null,
        pincode_name: null,
        start_date: null,
        end_date: null
      },
      updatedAddressRecord: {
        address_type_lookup_meaning: null,
        address_name: null,
        address_1: null,
        address_2: null,
        address_3: null,
        country_name: null,
        state_name: null,
        city_name: null,
        pincode_name: null,
        posting_status_meaning: null,
        approval_date: null,
        approval_status: null,
        approved_by: null,
        rejected_reason: null
      },
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        }
      ],
      showRejectAddressModal: false,
      reject: null,
      showDownloadModal: false,
      showOpenKmModal: false,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      viewDocumentFlag: false,
      addressSubMenuId: null
    };
  },
  mounted() {
    this.getPartySubMenuId();
    this.fillRecordsUpdatedRecord();
    if (this.addressRowDetail.address_id) {
      this.getPartyPortalExistingAddressRecord();
    } else {
      this.existingAddressRecord.address_type = this.defaultValue;
      this.existingAddressRecord.address_name = this.defaultValue;
      this.existingAddressRecord.address_1 = this.defaultValue;
      this.existingAddressRecord.address_2 = this.defaultValue;
      this.existingAddressRecord.address_3 = this.defaultValue;
      this.existingAddressRecord.country_name = this.defaultValue;
      this.existingAddressRecord.state_name = this.defaultValue;
      this.existingAddressRecord.city_name = this.defaultValue;
      this.existingAddressRecord.pincode_name = this.defaultValue;
      this.existingAddressRecord.start_date = this.defaultValue;
      this.existingAddressRecord.end_date = this.defaultValue;
    }
  },
  methods: {
    fillRecordsUpdatedRecord() {
      this.updatedAddressRecord = this.addressRowDetail;
    },
    getPartyPortalExistingAddressRecord() {
      const payload = {
        party_id: this.addressRowDetail.party_id,
        address_id:
          this.addressRowDetail.address_id ||
          this.updatedAddressRecord.address_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartyPortalExistingAddressRecord', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.existingAddressRecord = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showApproveAddress() {
      const payload = {
        pp_address_id: this.addressRowDetail.pp_address_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/approvePartyPortalAddress', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.updatedAddressRecord = response.data.data;
            this.getPartyPortalExistingAddressRecord();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHideRejectAddressModal(flag) {
      this.showRejectAddressModal = flag;
    },
    rejectAddress() {
      if (this.reject) {
        const payload = {
          pp_address_id: this.addressRowDetail.pp_address_id,
          rejected_reason: this.reject
        };
        this.loader = true;
        this.$store
          .dispatch('portalRealApp/rejectPartyPortalAddress', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.updatedAddressRecord = response.data.data;
              this.showRejectAddressModal = false;
              this.reject = null;
              this.getPartyPortalExistingAddressRecord();
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
              this.reject = null;
              this.showRejectAddressModal = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Enter Reject Reason');
      }
    },
    getPartySubMenuId() {
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartySubMenuId')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.addressSubMenuId =
              response.data.data.pp_party_address_sub_menu;
            this.getUploadedDocDtl();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      // if (flag) {
      //   this.getUploadedDocDtl();
      // }
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    getOpenKmAuthToken() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: 326,
        sub_menu_id: this.addressSubMenuId,
        record_id: this.addressRowDetail.pp_address_id
      };
      this.downloadItems = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.viewDocumentFlag = true;
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
};
