import commonHelper from '../../../../../utility/common.helper.utility';
import updateDirectTaxDetails from './updateDirectTax';
export default {
  name: 'directTax',
  watch: {
    currentPage: function() {
      this.getPartyPortalDirectTaxById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPartyPortalDirectTaxById();
    }
  },
  components: { commonHelper, updateDirectTaxDetails },
  props: ['partyDetails'],
  data() {
    return {
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      directTaxData: [],
      directTaxFields: [
        {
          key: 'tax_group'
        },
        {
          key: 'tan_no',
          label: 'TAN No.'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'posting_status_meaning',
          label: 'Posting Status'
        },
        {
          key: 'approval_date'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'approved_by'
        },
        {
          key: 'rejected_reason'
        }
      ],
      showAddDirectTaxUpdateModal: false,
      directTaxRowDetail: null,
      documentFlag: false,
      itemValue: null
    };
  },
  mounted() {
    this.getPartyPortalDirectTaxById();
  },
  methods: {
    getPartyPortalDirectTaxById() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        party_id: this.partyDetails.party_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartyPortalDirectTaxById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.directTaxData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.directTaxRowDetail = item;
      this.showHideDirectTaxUpdateModal(true);
    },
    showHideDirectTaxUpdateModal(flag) {
      this.showAddDirectTaxUpdateModal = flag;
      if (!flag) {
        this.getPartyPortalDirectTaxById();
      }
    }
  }
};
