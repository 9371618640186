import commonHelper from '../../../../../utility/common.helper.utility';
import updateIndirectTaxDetails from './updateIndirectTax';
export default {
  name: 'indirectTax',
  watch: {
    currentPage: function() {
      this.getPartyPortalindirectTaxById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPartyPortalindirectTaxById();
    }
  },
  components: { commonHelper, updateIndirectTaxDetails },
  props: ['partyDetails'],
  data() {
    return {
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      indirectTaxData: [],
      indirectTaxFields: [
        {
          key: 'tax_group'
        },
        {
          key: 'issuing_authority'
        },
        {
          key: 'tax_num',
          label: 'Tax Number'
        },
        {
          key: 'state'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'posting_status_meaning',
          label: 'Posting Status'
        },
        {
          key: 'approval_date'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'approved_by'
        },
        {
          key: 'rejected_reason'
        }
      ],
      showAddIndirectTaxUpdateModal: false,
      indirectTaxRowDetail: null
    };
  },
  mounted() {
    this.getPartyPortalindirectTaxById();
  },
  methods: {
    getPartyPortalindirectTaxById() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        party_id: this.partyDetails.party_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartyPortalIndirectTaxById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.indirectTaxData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.indirectTaxRowDetail = item;
      this.showHideIndirectTaxUpdateModal(true);
    },
    showHideIndirectTaxUpdateModal(flag) {
      this.showAddIndirectTaxUpdateModal = flag;
      if (!flag) {
        this.getPartyPortalindirectTaxById();
      }
    }
  }
};
