import DatePicker from 'vue2-datepicker';
import commonHelper from '../../../utility/common.helper.utility';
import updatePartyDetails from '../updateParty/updatePartyDetails';
import party from '@/app/components/generalLedger/party';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'updateParty',
  components: {
    DatePicker,
    party,
    updatePartyDetails
  },
  watch: {
    currentPage: function() {
      this.getPayableInvoiceList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPayableInvoiceList();
    }
  },
  data() {
    return {
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showValueSetModal: false,
      showUpdatePartyModal: false,
      showPartyModal: false,
      partyDetails: null,
      appStatus: {
        value: null,
        text: null
      },
      party: {
        id: null,
        name: null
      },
      // updationType: {
      //   value: null,
      //   text: null
      // },
      // requestDate: [],
      updatePartyData: [],
      updatePartyFields: [
        {
          key: 'party_name'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'updation_type'
        },
        {
          key: 'request_date'
        },
        {
          key: 'rejected_reason'
        }
      ]
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          if (this.updatePartyData.length > 0) {
            this.loader = true;
            /**
             * @param(payload, 'action name', 'file name')
             */
            const downloadpayload = { ...this.payload };
            downloadpayload._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'portalRealApp/getPartyPortalUpdateStatus',
              'party-portal',
              () => (this.loader = false)
            );
          } else {
            alert('Please Search the Data First');
          }
        }
      }
    });
  },
  methods: {
    searchGridData() {
      this.getPayableInvoiceList();
    },
    getPayableInvoiceList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        party_id: this.party.id,
        approval_status: this.appStatus.value
        // updation_type_vset: this.updationType.value
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartyPortalUpdateStatus', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.updatePartyData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.partyDetails = item;
      this.showUpdatePartyModal = true;
    },
    clearFilters() {
      this.updatePartyData = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.appStatus = {
        value: null,
        text: null
      };
      this.party = {
        id: null,
        name: null
      };
      this.updationType = {
        value: null,
        text: null
      };
      this.requestDate = [];
    },
    showHideUpdateParty(flag) {
      this.showUpdatePartyModal = flag;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.PP_APPROVAL_STATUS) {
        this.appStatus.text = item.value_meaning;
        this.appStatus.value = item.value_code;
      }
    },
    showHidePartyModal(flag) {
      this.showPartyModal = flag;
    },
    getSelectedPartyCustomer(item) {
      this.party.name = item.party_name;
      this.party.id = item.party_id;
      this.showPartyModal = false;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
