import appStrings from '@/app/utility/string.utility';
import commonHelper from '../../../../../../utility/common.helper.utility';
import URL_UTILITY from '../../../../../../utility/url.utility';
export default {
  name: 'udpateBank',
  components: {},
  props: ['bankRowDetail'],
  data() {
    return {
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      defaultValue: 'No Record Available',
      existingBankRecord: {
        bank_name: null,
        bank_branch: null,
        account_type: null,
        account_num: null,
        ifsc_code: null,
        swift_code: null,
        active: false
      },
      updatedBankRecord: {
        bank_name: null,
        bank_branch: null,
        account_type: null,
        account_num: null,
        ifsc_code: null,
        swift_code: null,
        active: false,
        posting_status_meaning: null,
        approval_date: null,
        approval_status: null,
        approved_by: null,
        rejected_reason: null
      },
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        }
      ],
      showRejectBankModal: false,
      reject: null,
      showDownloadModal: false,
      bankId: null,
      showOpenKmModal: false,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      viewDocumentFlag: false,
      bankSubMenuId: null
    };
  },
  mounted() {
    this.getPartySubMenuId();
    this.fillRecordsUpdatedRecord();
    if (this.bankRowDetail.party_bank_id) {
      this.getPartyPortalExistingBankRecord();
    } else {
      this.existingBankRecord.bank_name = this.defaultValue;
      this.existingBankRecord.bank_branch = this.defaultValue;
      this.existingBankRecord.account_type = this.defaultValue;
      this.existingBankRecord.account_num = this.defaultValue;
      this.existingBankRecord.ifsc_code = this.defaultValue;
      this.existingBankRecord.swift_code = this.defaultValue;
    }
  },
  methods: {
    fillRecordsUpdatedRecord() {
      this.updatedBankRecord = this.bankRowDetail;
    },
    getPartyPortalExistingBankRecord() {
      const payload = {
        party_bank_id:
          this.bankRowDetail.party_bank_id ||
          this.updatedBankRecord.party_bank_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartyPortalExistingBankRecord', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.existingBankRecord = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showApproveBank() {
      const payload = {
        pp_party_bank_id: this.bankRowDetail.pp_party_bank_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/approvePartyPortalBank', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.updatedBankRecord = response.data.data;
            this.getPartyPortalExistingBankRecord();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHideRejectBankModal(flag) {
      this.showRejectBankModal = flag;
    },
    rejectBank() {
      if (this.reject) {
        const payload = {
          pp_party_bank_id: this.bankRowDetail.pp_party_bank_id,
          rejected_reason: this.reject
        };
        this.loader = true;
        this.$store
          .dispatch('portalRealApp/rejectPartyPortalBank', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.updatedBankRecord = response.data.data;
              this.showRejectBankModal = false;
              this.reject = null;
              this.getPartyPortalExistingBankRecord();
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
              this.reject = null;
              this.showRejectBankModal = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Enter Reject Reason');
      }
    },
    getPartySubMenuId() {
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartySubMenuId')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.bankSubMenuId =
              response.data.data.pp_party_drawee_bank_sub_menu;
            this.getUploadedDocDtl();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      // if (flag) {
      //   this.getUploadedDocDtl();
      // }
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    getOpenKmAuthToken() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: 326,
        sub_menu_id: this.bankSubMenuId,
        record_id: this.bankRowDetail.pp_party_bank_id
      };
      this.downloadItems = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.viewDocumentFlag = true;
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
  }
};
