import commonHelper from '../../../../../utility/common.helper.utility';
import updateBankDetails from './updateBank';
export default {
  name: 'bank',
  watch: {
    currentPage: function() {
      this.getPartyPortalBankById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getPartyPortalBankById();
    }
  },
  components: { commonHelper, updateBankDetails },
  props: ['partyDetails'],
  data() {
    return {
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      bankData: [],
      bankFields: [
        {
          key: 'bank_name'
        },
        {
          key: 'bank_branch'
        },
        {
          key: 'account_type'
        },
        {
          key: 'account_num',
          label: 'Account Number'
        },
        {
          key: 'ifsc_code',
          label: 'IFSC Code'
        },
        {
          key: 'swift_code',
          label: 'SWIFT Code'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'posting_status_meaning',
          label: 'Posting Status'
        },
        {
          key: 'approval_date'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'approved_by'
        },
        {
          key: 'rejected_reason'
        }
      ],
      showAddBankUpdateModal: false,
      bankRowDetail: null
    };
  },
  mounted() {
    this.getPartyPortalBankById();
  },
  methods: {
    getPartyPortalBankById() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        party_id: this.partyDetails.party_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPartyPortalBankById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.bankData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    rowSelected(item) {
      this.bankRowDetail = item;
      this.showHideBankUpdateModal(true);
    },
    showHideBankUpdateModal(flag) {
      this.showAddBankUpdateModal = flag;
      if (!flag) {
        this.getPartyPortalBankById();
      }
    }
  }
};
