import Details from './details';
import Address from './address';
import Bank from './bank';
import ContactDetails from './contactDetails';
import DirectTax from './directTax';
import IndirectTax from './indirectTax';
// import appStrings from '../../../../utility/string.utility';
export default {
  name: 'updatePartyDetails',
  components: {
    Details,
    Address,
    Bank,
    DirectTax,
    IndirectTax,
    ContactDetails
  },
  props: ['partyDetails'],
  data() {
    return {
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      activeTab: '',
      tabIndex: null
    };
  },
  created() {
    this.setTabIndex();
    this.selectedTab(this.partyDetails.updation_type);
  },
  methods: {
    selectedTab(tab) {
      this.activeTab = tab;
    },
    setTabIndex() {
      switch (this.partyDetails.updation_type) {
        case 'Address':
          this.tabIndex = 1;
          break;
        case 'Bank':
          this.tabIndex = 2;
          break;
        case 'Contact Details':
          this.tabIndex = 3;
          break;
        case 'InDirect Tax':
          this.tabIndex = 4;
          break;
        case 'Direct Tax':
          this.tabIndex = 5;
          break;
        default:
          this.tabIndex = 0;
          break;
      }
    }
  }
};
