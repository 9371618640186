import appStrings from '@/app/utility/string.utility';
export default {
  name: 'udpateContactDetails',
  components: {},
  props: ['contactDetailsRowDetail'],
  data() {
    return {
      currentPage: 1,
      totalRows: null,
      loader: false,
      editMode: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      existingDetailsData: [
        {
          communication_details: []
        }
      ],
      existingDetailsFields: [
        {
          key: 'communication'
        },
        {
          key: 'contact_purpose'
        },
        {
          key: 'salutation'
        },
        {
          key: 'first_name'
        },
        {
          key: 'middle_name'
        },
        {
          key: 'last_name'
        },
        {
          key: 'contact_purpose'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'active',
          stickyColumn: true
        }
      ],
      existed_contact_details_fields: [
        {
          key: 'comm_type',
          label: 'Contact Type'
        },
        {
          key: 'area_code'
        },
        {
          key: 'country_code'
        },
        {
          key: 'contact_num',
          label: 'Contact Number'
        },
        {
          key: 'email'
        },
        {
          key: 'active',
          stickyColumn: true
        }
      ],
      updatedDetailsData: [
        {
          communication_details: []
        }
      ],
      updatedDetailsFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'communication'
        },
        {
          key: 'contact_purpose'
        },
        {
          key: 'salutation'
        },
        {
          key: 'first_name'
        },
        {
          key: 'middle_name'
        },
        {
          key: 'last_name'
        },
        {
          key: 'contact_purpose'
        },
        {
          key: 'start_date'
        },
        {
          key: 'end_date'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'posting_status_meaning',
          label: 'Posting Status'
        },
        {
          key: 'approval_date'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'approved_by'
        },
        {
          key: 'rejected_reason'
        }
      ],
      updated_contact_details_fields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'comm_type',
          label: 'Contact Type'
        },
        {
          key: 'area_code'
        },
        {
          key: 'country_code'
        },
        {
          key: 'contact_num',
          label: 'Contact Number'
        },
        {
          key: 'email'
        },
        {
          key: 'active',
          stickyColumn: true
        },
        {
          key: 'posting_status_meaning',
          label: 'Posting Status'
        },
        {
          key: 'approval_date'
        },
        {
          key: 'approval_status'
        },
        {
          key: 'approved_by'
        },
        {
          key: 'rejected_reason'
        }
      ],
      existedDetailIndex: null,
      updatedDetailIndex: null,
      showRejectContactDetailsModal: false,
      approveRejectButton: false,
      reject: null,
      updatedDetailId: null
    };
  },
  mounted() {
    this.getPartyPortalExistedContactDetailsById();
    this.getPartyPortalUpdatedContactDetailsById();
  },
  methods: {
    showExistingDetails(index, item) {
      this.existedDetailIndex = index;
      item.toggleDetails();
    },
    showUpdatedDetails(index, item) {
      this.updatedDetailIndex = index;
      item.toggleDetails();
      // if (item.item.approval_status === 'APPROVED') {
      //   item.toggleDetails();
      // } else {
      //   alert('Please Approve the Headers First');
      // }
    },
    getPartyPortalExistedContactDetailsById() {
      const payload = {
        party_contact_person_id:
          this.contactDetailsRowDetail.party_contact_person_id ||
          this.updatedDetailId
      };
      this.loader = true;
      this.$store
        .dispatch(
          'portalRealApp/getPartyPortalExistedContactDetailsById',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.existingDetailsData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getPartyPortalUpdatedContactDetailsById() {
      const payload = {
        pp_party_contact_person_id: this.contactDetailsRowDetail
          .pp_party_contact_person_id
      };
      this.loader = true;
      this.$store
        .dispatch(
          'portalRealApp/getPartyPortalUpdatedContactDetailsById',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.updatedDetailsData = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideRejectContactDetailsModal(flag) {
      this.showRejectContactDetailsModal = flag;
    },
    selectedUpdatedDetails(item) {
      this.invoiceDetails = {};
      this.invoiceDetails = Object.assign(this.invoiceDetails, item);
      if (item.approval_status === 'DRAFT') {
        this.approveRejectButton = true;
      } else {
        this.approveRejectButton = false;
      }
    },
    approveDetails() {
      if (
        this.invoiceDetails.pp_party_contact_person_id &&
        !this.invoiceDetails.pp_party_contact_comm_id
      ) {
        this.showApproveContactDetails();
      } else if (
        this.invoiceDetails.pp_party_contact_person_id &&
        this.invoiceDetails.pp_party_contact_comm_id
      ) {
        this.showApproveContactCommunication();
      }
    },
    showApproveContactDetails() {
      const payload = {
        pp_party_contact_person_id: this.invoiceDetails
          .pp_party_contact_person_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/approvePartyPortalContactDetails', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.updatedDetailsData = response.data.data;
            this.updatedDetailId =
              response.data.data[0].party_contact_person_id;
            this.approveRejectButton = false;
            this.getPartyPortalExistedContactDetailsById();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
            this.approveRejectButton = false;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showApproveContactCommunication() {
      const payload = {
        pp_party_contact_comm_id: this.invoiceDetails.pp_party_contact_comm_id
      };
      this.loader = true;
      this.$store
        .dispatch(
          'portalRealApp/approvePartyPortalContactCommunication',
          payload
        )
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 201) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.updatedDetailsData = response.data.data;
            this.updatedDetailId =
              response.data.data[0].party_contact_person_id;
            this.approveRejectButton = false;
            this.getPartyPortalExistedContactDetailsById();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
            this.approveRejectButton = false;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    rejectDetails() {
      if (
        this.invoiceDetails.pp_party_contact_person_id &&
        !this.invoiceDetails.pp_party_contact_comm_id
      ) {
        this.rejectContactDetails();
      } else if (
        this.invoiceDetails.pp_party_contact_person_id &&
        this.invoiceDetails.pp_party_contact_comm_id
      ) {
        this.rejectContactCommunication();
      }
    },
    rejectContactDetails() {
      if (this.reject) {
        const payload = {
          pp_party_contact_person_id: this.invoiceDetails
            .pp_party_contact_person_id,
          rejected_reason: this.reject
        };
        this.loader = true;
        this.$store
          .dispatch('portalRealApp/rejectPartyPortalContactDetails', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.reject = null;
              this.updatedDetailsData = response.data.data;
              this.showRejectContactDetailsModal = false;
              this.approveRejectButton = false;
              this.getPartyPortalExistedContactDetailsById();
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
              this.reject = null;
              this.showRejectContactDetailsModal = false;
              this.approveRejectButton = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Enter Reject Reason');
      }
    },
    rejectContactCommunication() {
      if (this.reject) {
        const payload = {
          pp_party_contact_comm_id: this.invoiceDetails
            .pp_party_contact_comm_id,
          rejected_reason: this.reject
        };
        this.loader = true;
        this.$store
          .dispatch(
            'portalRealApp/rejectPartyPortalContactCommunication',
            payload
          )
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.reject = null;
              this.updatedDetailsData = response.data.data;
              this.showRejectContactDetailsModal = false;
              this.approveRejectButton = false;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
              this.reject = null;
              this.showRejectContactDetailsModal = false;
              this.approveRejectButton = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Enter Reject Reason');
      }
    }
  }
};
